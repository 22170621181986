import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage"
import { getFirestore } from "firebase/firestore"

const firebaseConfig = {
  apiKey: "AIzaSyDNbmiT9AH1fIUmXg2cOf1nCuLQdkKdw6I",
  authDomain: "baburangu-e3f58.firebaseapp.com",
  projectId: "baburangu-e3f58",
  storageBucket: "baburangu-e3f58.appspot.com",
  messagingSenderId: "464389254270",
  appId: "1:464389254270:web:144ce3094ba263615ebc2f"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const imgDB = getStorage(app)
const txtDB = getFirestore(app)

export {imgDB,txtDB};