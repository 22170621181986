import React from 'react';
import pds from "./pds.jpg"
import anx from "./ANXIETY.jpg"
import bdd from "./behaviour.jpg"
//import { motion } from "framer-motion";
import Button from './Button';
import { NavLink } from "react-router-dom";
// Step 1: Create a single card component
//import { NavLink } from "react-router-dom";
//import { useState } from "react";


//const [isMenuOpen, setIsMenuOpen] = useState(false);

const styles22={
    card: {
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
        transition: '0.3s',
        width: '20%',
        textAlign: 'center',
        backgroundColor: '#48a3c6',
        margin: '30px',
        paddingTop: '10px',
        position: 'relative'
      },
      container: {
        padding: '2px 16px'
      },
      inc: {
        textAlign: 'center'
      },
      flx: {
        display: 'flex',
        justifyContent: 'center',
      },
      bt: {
        position: 'absolute',
        bottom: 0,
        
      }
      
}

function Card({ imageSrc, title, description }) {
  return (
    <div className="flex flex-col max-w-xs bg-[#48a3c6]  rounded-md">
      <img src={imageSrc} alt={title} className="rounded-t-md" />
      <div className="px-1">
        <h2 className="text-xl text-white opacity-75 font-medium">{title}</h2>
        <p className="text-lg mt-1">{description}</p>
      </div>
    </div>
  );
}

// Step 2: Create a page component that renders multiple cards
function CardPage({ cardData }) {
  return (
    <div className="card-page flex-wrap flex flex-col items-center gap-10 md:flex-row md:items-stretch md:justify-center ">
      {cardData.map((card, index) => (
        <Card
          key={index} // Use a unique key for each card
          imageSrc={card.imageSrc}
          title={card.title}
          description={card.description}
        />
      ))}
    </div>
  );
}

// Example data array of objects
const cards = [
  {
    imageSrc: pds,
    title: 'Psychotic disorder',
    description: 'Psychotic disorders are severe mental disorders that cause abnormal thinking and perceptions. People with psychoses lose touch with reality. Two of the main symptoms are delusions and hallucinations.',
  },
  {
    imageSrc: anx,
    title: 'Anxiety',
    description: "Anxiety is the mind and body's reaction to stressíul, dangerous, or unfamiliar situations. It's the sense of uneasiness,distress, or dread you feel before a significant event. A certain level of Anxiety helps us stay alert and aware, but for those suffering trom an anxiety disorder, it feels far from normal -it can be completely debilitating.",
  },
  {
    imageSrc: bdd,
    title: 'Behavioral and development disorder',
    description: "Developmental and behavioral disorders including intellectual disability, learning disabilities, and attention-deficit/hyperactivity disorder are highly prevalent, chronic health conditions.",
  }
];

function Others() {
  return (
    <div className='px-10'>
      <CardPage cardData={cards}/>
      <NavLink to='/Therapy' onClick={() => true}>
        <Button name='Go Back'/>
      </NavLink>
    </div>
  );
}

export default Others;
