import React from 'react';
import dep from "./depression.jpeg"
import man from "./mania.jpg"
import ed from "./eating disorder.jpg"
import pd from "./personality disorder.jpeg"
import ob from "./Obsessive compulsion.jpeg"
//import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import Button from './Button';
// Step 1: Create a single card component
//import { NavLink } from "react-router-dom";
//import { useState } from "react";


//const [isMenuOpen, setIsMenuOpen] = useState(false);

const styles22={
    card: {
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
        transition: '0.3s',
        width: '20%',
        textAlign: 'center',
        backgroundColor: '#48a3c6',
        margin: '30px',
        paddingTop: '10px',
        position: 'relative'
      },
      container: {
        padding: '2px 16px'
      },
      inc: {
        textAlign: 'center'
      },
      flx: {
        display: 'flex',
        justifyContent: 'center',
      },
      bt: {
        position: 'absolute',
        bottom: 0,
        
      }
      
}

function Card({ imageSrc, title, description }) {
  return (
    <div className="flex flex-col max-w-xs bg-[#48a3c6]  rounded-md">
      <img src={imageSrc} alt={title} className="rounded-t-md" />
      <div className="px-1">
        <h2 className="text-xl text-white opacity-75 font-medium">{title}</h2>
        <p className="text-lg mt-1">{description}</p>
      </div>
    </div>
  );
}

// Step 2: Create a page component that renders multiple cards
function CardPage({ cardData }) {
  return (
    <div className="card-page flex-wrap flex flex-col items-center gap-10 md:flex-row md:items-stretch md:justify-center ">
      {cardData.map((card, index) => (
        <Card
          key={index} // Use a unique key for each card
          imageSrc={card.imageSrc}
          title={card.title}
          description={card.description}
        />
      ))}
    </div>
  );
}

// Example data array of objects
const cards = [
  {
    imageSrc: dep,
    title: 'Depression',
    description: "Depression is classified as a mood disorder. It may be described as feelings of sadness, loss, or anger that interfere with a person's everyday activities. It's also fairly common.Data from the Centers for Disease Control and Prevention estimates that 18.5 percent of American adults had symptoms of depression in any given 2-week period in 2019.",
  },
  {
    imageSrc: man,
    title: 'Mania',
    description: 'Mania is a condition in which you have a period of abnormally elevated, extreme changes in your mood or emotions, energy level or activity level.This highly energized level of physical and mental activity and behavior must be a change from your usual self and be noticeable by others.',
  },
  {
    imageSrc: ed,
    title: 'Eating disorder',
    description: 'Eating disorders are behavioral conditions characterized by severe and persistent disturbance in eating behaviors and associated distressing thoughts and emotions. They can be very serious conditions affecting physical psychological and social function.',
  },
  {
    imageSrc: pd,
    title: 'Personality disorder',
    description: 'A personality disorder is a type of mental disorder in which you have a rigid and unhealthy pattern of thinking. functioning and behaving . A person with a personality disorder has trouble perceiving and relating to situations and people.',
  },
  {
    imageSrc: ob,
    title: 'Obessive compulsive',
    description: 'Obsessive-compulsive disorder (OCD) features a pattern of unwanted thoughts and fears (obsessions) that lead you to do repetitive behaviors(compulsions). These obsessions and compulsions interfere with daily activities and cause significant distress.',
  }
  // Add more card objects as needed
];

function Disorder() {
  return (
    <div className='px-10'>
      <CardPage cardData={cards}/>
        <NavLink to='/Therapy' onClick={() => true}>
            <Button name='Go Back'/>
        </NavLink>
    </div>
  );
}

export default Disorder;
