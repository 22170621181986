import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { txtDB } from "./txtImgConfig";
import { collection, getDocs } from "firebase/firestore";

export default function ImageGallery() {
  const [data, setData] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [password, setPassword] = useState("");
  const [showLink, setShowLink] = useState(false);

  const getData = async () => {
    const valRef = collection(txtDB, "txtData");
    const dataDb = await getDocs(valRef);
    const allData = dataDb.docs.map((val) => ({ ...val.data(), id: val.id }));
    setData(allData);
  };

  useEffect(() => {
    getData();
  }, []);

  const toggleDropdown = () => {
    setShowDropdown((prev) => !prev);
  };

  const handlePasswordCheck = () => {
    if (password === "admin123") {
      setShowLink(true);
    } else {
      alert("Incorrect password. Try again.");
    }
  };

  return (
    <div style={{ color: "var(--text-color)" }} className="p-5">
      <h1 className="text-3xl text-center">Image Gallery</h1>
      <div className="card-page mt-5 grid grid-flow-row md:grid-cols-3 gap-10">
        {data.map((value) => (
          <div className="flex flex-col max-w-xs bg-white rounded-md" key={value.id}>
            <img
              src={value.imgUrl}
              alt={`${value.id}`}
              className="md:h-52 rounded-t-md object-cover"
            />
            <div className="px-1">
              <h2 className="text-xl text-center">{value.title}</h2>
              <p className="text-lg mt-2 opacity-70">{value.description}</p>
            </div>
            <br />
          </div>
        ))}
      </div>
      <div className="dropdown" id="dropdown">
        <span onClick={toggleDropdown}>▼</span>
        {showDropdown && (
          <div className="dropdown-content" id="dropdownContent">
            <input
              type="password"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button onClick={handlePasswordCheck}>OK</button>
            {showLink && (
              <NavLink to="/upload" onClick={() => true}>
                <button>Continue</button>
              </NavLink>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
