import Hero from "../../components/Hero";
//import Draw from "../../components/Draw";
import About from "../../components/AboutMe";

/**
 * Represents the Landing page component.
 * Displays the main landing page content including Hero, Drawing, and About sections.
 *
 * @component
 * @param {string} name - The name of the user displayed in the Hero section.
 */

const Landing = ({ name }) => {
  // Inline styles for the main landing container
  const styles = {
    landing: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };

  return (
    <>
      {/* Main Landing Page */}
      <main className="landing container" style={styles.landing}>
        <Hero name="Babu Rangrajan" />
      </main>

      <About />
    </>
  );
};

export default Landing;
