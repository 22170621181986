import React from "react";
import stds from "./students.jpeg";
import pnt from "./Parents.jpg"
import tch from "./teachers.jpeg"
import strs from "./stress.jpeg"
import lght from "./laughter.jpeg"
import prdc from "./productivity.jpg"
// Step 1: Create a single card component
import { NavLink } from "react-router-dom";
//import { Routes, Route, useLocation, Navigate } from "react-router-dom";
//import { useState } from "react";

//const [isMenuOpen, setIsMenuOpen] = useState(false);

const styles22 = {
  card: {
    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
    transition: "0.3s",
    width: "20%",
    textAlign: "center",
    backgroundColor: "#48a3c6",
    margin: "30px",
    paddingTop: "10px",
    position: "relative",
  },
  container: {
    padding: "2px 16px",
  },
  inc: {
    textAlign: "center",
  },
  flx: {
    display: "flex",
    justifyContent: "center",
  },
  bt: {
    position: "absolute",
    bottom: 0,
  },
};

function Card({ imageSrc, title, description, link }) {
  return (
    <div className="flex flex-col max-w-xs bg-[#48a3c6]  rounded-md"  style={{marginTop:"50px",marginLeft:"100px",marginRight:"100px"}}>
      <img src={imageSrc} alt={title} className="rounded-t-md" />
      <div className="px-1">
        <h2 className="text-xl text-white opacity-75 font-medium"  style={{textAlign:"center"}}>{title}</h2>
        <p className="text-lg mt-1">{description}</p>
        <div className="mt-2">
        </div>
      </div>
      <br />
    </div>
  );
}

// Step 2: Create a page component that renders multiple cards
function CardPage({ cardData }) {
  return (
    <div className="card-page flex flex-col gap-10 items-center  md:flex-row md:gap-5 md:items-stretch md:justify-between ">
      {cardData.map((card, index) => (
        <Card
          key={index} // Use a unique key for each card
          imageSrc={card.imageSrc}
          title={card.title}
          description={card.description}
          link={card.link}
        />
      ))}
    </div>
  );
}

// Example data array of objects


const cards1 = [
  {
    imageSrc: stds,
    title: "Program For Students",
    description: <ol>
      <li>1. Student psychology</li>
      <li>2. Classroom management</li>
      <li>3. Discipline mastery</li>
      <li>4. Stress management</li>
      <li>5. Mind mastery</li>
      <li>6. Happy classroom</li>
    </ol>,
    link: "/Children",
  },
  {
    imageSrc: tch,
    title: "Program For Teachers",
    description: <ol>
      <li>1. Concentration mastery</li>
      <li>2. Overcome addiction mastery</li>
      <li>3. Carrer counseling</li>
      <li>4. Exam mastery</li>
      <li>5. Confidence mastery</li>
    </ol>,
    link: "/Disorder",
  },
  {
    imageSrc: pnt,
    title: "Program For Parents",
    description: <ol>
      <li>1. Handling children</li>
      <li>2. Bonding</li>
      <li>3. Disciplining children friendly</li>
      <li>4. Confidence enhancement</li>
      <li>5. Positive parenting </li>
    </ol>,
    link: "/Others",
  },
  // Add more card objects as needed
];


const cards2 = [
  {
    imageSrc: prdc,
    title: "Session On Productivity",
    description: <ol>
      <li>1. Understand your potential</li>
      <li>2. Happy environment</li>
      <li>3. Who controls your potential?</li>
      <li>4. To bring the best in you</li>
    </ol>,
    link: "/Children",
  },
  {
    imageSrc: lght,
    title: "Session On Laughter Yoga",
    description: <ol>
    <li>1. Laughter clap</li>
    <li>2. Laughter breathe</li>
    <li>3. Laughter activity</li>
    <li>4. Child like playfulness</li>
  </ol>,
    link: "/Disorder",
  },
  {
    imageSrc: strs,
    title: "Session n Stess Management",
    description: <ol>
    <li>1. Reprogramming your mind</li>
    <li>2. Knowing about stress</li>
    <li>3. Confidence enhancement</li>
    <li>4. Influence affect your ming</li>
  </ol>,
    link: "/Others",
  },
  // Add more card objects as needed
];

function Programs() {
  return (
    <div className="px-10">
      <h1 style={{textAlign:"center",fontSize:"40px"}}>Educational Institution programs</h1>
      <CardPage cardData={cards1} />
      <h1 style={{textAlign:"center",fontSize:"40px",paddingTop:"20px"}}>Corporate Programs</h1>
      <CardPage cardData={cards2} />
    </div>
  );
}

export default Programs;
