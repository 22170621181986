import React from 'react';
import iq from "./iq.jpeg"
import ld from "./ld.jpeg"
import asa from "./asa.jpeg"
import ct from "./ct.png"
import it from "./it.png"
import pa from "./pa.jpeg"
//import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import Button from './Button';


function Card({ imageSrc, title, description }) {
  return (
    <div className="flex flex-col max-w-xs bg-[#48a3c6]  rounded-md">
      <img src={imageSrc} alt={title} className="rounded-t-md" />
      <div className="px-1">
        <h2 className="text-xl text-white opacity-75 font-medium">{title}</h2>
        <p className="text-lg mt-1">{description}</p>
      </div>
    </div>
  );
}

// Step 2: Create a page component that renders multiple cards
function CardPage({ cardData }) {
  return (
    <div className="card-page flex-wrap flex flex-col items-center gap-10 md:flex-row md:items-stretch md:justify-center ">
      {cardData.map((card, index) => (
        <Card
          key={index} // Use a unique key for each card
          imageSrc={card.imageSrc}
          title={card.title}
          description={card.description}
        />
      ))}
    </div>
  );
}

// Example data array of objects
const cards = [
  {
    imageSrc: iq,
    title: 'IQ test',
    description: 'An IQ test is an assessment that measures a range of cognitive abilities and provides a score that is intended to serve as a measure of an individual’s intellectual abilities and potential.IQ tests are among the most administered psychological tests.',
  },
  {
    imageSrc: ld,
    title: 'Learning disabilities',
    description: 'Leaming disability is an inability to benefit from the instructions or practices provided in the learning situation despite normal intelligence and the absence of other physical and major psychological problems.',
  },
  {
    imageSrc: asa,
    title: 'Austim spectrum assessment',
    description: 'Autism spectrum disorder (ASD) refers any one of a group of disorders with an onset typically occurring during the preschool years and characterized by difficulties with social communication and social interaction and restricted and repetitive patterns in behaviors, interests, and activities.',
  },
  {
    imageSrc: ct,
    title: 'Career test',
    description: 'Career assessments are tools that are designed to help individuals understand how a variety of personal attributes(i.e., data values. preferences, motivations, aptitudes and skills), impact their potential success and satisfaction with different career options and work environments.',
  },
  {
    imageSrc: it,
    title: 'Interest testing',
    description: "A test that determines a person's preferenccs for specific fields or activities. An interest inventory is a testing instrument designed for the purpose of measuring and evaluating the level of an individual's interest in, or preference for, a variety of activities;also known as interest test.",
  },
  {
    imageSrc: pa,
    title: 'Personality assessment',
    description: 'Personality assessment is conducted through behavioral observations, paper.and pencil tests, and projective techniques . To be useful, such assessments must be constructed using the established criteria of standardization, reliability, and validity.',
  }
  // Add more card objects as needed
];

function Children() {
  return (
    <div className='px-10'>
      <CardPage cardData={cards}/>
        <NavLink to='/Therapy' onClick={() => true}>
            <Button name='Go Back'/>
        </NavLink>
    </div>
  );
}

export default Children;
