import React from "react";
import chd from "./children.jpg";
import psh from "./psychotic.jpg"
import othr from "./others.jpeg"
// Step 1: Create a single card component
import { NavLink } from "react-router-dom";
//import { Routes, Route, useLocation, Navigate } from "react-router-dom";
//import { useState } from "react";

//const [isMenuOpen, setIsMenuOpen] = useState(false);

const styles22 = {
  card: {
    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
    transition: "0.3s",
    width: "20%",
    textAlign: "center",
    backgroundColor: "#48a3c6",
    margin: "30px",
    paddingTop: "10px",
    position: "relative",
  },
  container: {
    padding: "2px 16px",
  },
  inc: {
    textAlign: "center",
  },
  flx: {
    display: "flex",
    justifyContent: "center",
  },
  bt: {
    position: "absolute",
    bottom: 0,
  },
};

function Card({ imageSrc, title, description, link }) {
  return (
    <div className="flex flex-col max-w-xs bg-[#48a3c6]  rounded-md" style={{padding:"20px"}}>
      <img src={imageSrc} alt={title} className="rounded-t-md" />
      <div className="px-1">
        <h2 className="text-xl text-white opacity-75 font-medium">{title}</h2>
        <div className="text-lg mt-1">{description}</div>
        <div className="mt-2">
          <NavLink to={link} onClick={() => true} style={{color:"blue"}}>
            Tap for more
          </NavLink>
        </div>
      </div>
      <br />
    </div>
  );
}

// Step 2: Create a page component that renders multiple cards
function CardPage({ cardData }) {
  return (
    <div className="card-page flex flex-col gap-10 items-center  md:flex-row md:gap-5 md:items-stretch md:justify-between " style={{margin:"50px"}}>
      {cardData.map((card, index) => (
        <Card
          key={index} // Use a unique key for each card
          imageSrc={card.imageSrc}
          title={card.title}
          description={card.description}
          link={card.link}
        />
      ))}
    </div>
  );
}

// Example data array of objects
const cards = [
  {
    imageSrc: chd,
    title: "Children",
    description: <ol><li>1. IQ Test</li>
    <li>2. Learning Disabilities</li>
    <li>3. Austim Spectrum</li>
    <li>4. Career Test</li>
    <li>5. Intrest Test</li>
    <li>6. Personality Assesment</li>
    <li>7. Compulsion Disorder</li>
    </ol>,
    link: "/Children",
  },
  {
    imageSrc: psh,
    title: "Psychodiagnostic Disorder",
    description: <ol>
      <li>1. Depression</li>
      <li>2. Mania</li>
      <li>3. Eating Disorder</li>
      <li>4. Personality Disorder</li>
      <li>5. Obsessive Compulsive</li>
    </ol>,
    link: "/Disorder",
  },
  {
    imageSrc: othr,
    title: "Others",
    description:<ol>
      <li>1. Psychotic Disorder</li>
      <li>2. Anxiety</li>
      <li>3. Bihavioral Disorder</li>
      {/* <li>4. Development Disorder</li> */}
    </ol>,
    link: "/Others",
  },
  // Add more card objects as needed
];

function CardY() {
  return (
    <div className="px-10">
      <CardPage cardData={cards} />
    </div>
  );
}

export default CardY;
