import React, { useState, useEffect, useRef } from "react";
import { imgDB, txtDB } from "./txtImgConfig";
import { v4 } from "uuid";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { addDoc, collection, getDocs, deleteDoc, doc } from "firebase/firestore";

export default function ImageUpload() {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [img, setImg] = useState('');
  const [data, setData] = useState([]);
  const myref = useRef(null); 

  const handleUpload = (e) => {
    const imgs = ref(imgDB, `Imgs/${v4()}`);
    uploadBytes(imgs, e.target.files[0]).then(snapshot => {
      getDownloadURL(snapshot.ref).then(downloadURL => {
        setImg(downloadURL);
      });
    });
  };

  const handleClick = async () => {
    const valRef = collection(txtDB, 'txtData');
    await addDoc(valRef, { title: title, description: description, imgUrl: img });
    alert("Data added successfully");
    getData(); // Refresh data after adding
  };

  const getData = async () => {
    const valRef = collection(txtDB, 'txtData');
    const dataDb = await getDocs(valRef);
    const allData = dataDb.docs.map(val => ({ ...val.data(), id: val.id }));
    setData(allData);
  };

  const handleDelete = async (id) => {
    const valRef = collection(txtDB, 'txtData');
    await deleteDoc(doc(valRef, id));
    getData(); // Refresh data after deletion
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="px-10 py-8">
      <h1 className="text-3xl text-center">Admin Dashboard</h1>
      <div
        className="border-2 rounded-md border-dashed max-w-4xl h-52 mx-auto mt-6 flex justify-center items-center cursor-pointer"
        onClick={() => myref.current.click()}
      >
        <input
          type="file"
          className="hidden"
          accept="image/*"
          onChange={(e) => handleUpload(e)}
          // onChange={handleImageChange}
          // ref={myref}
          ref={myref}
        />
        <p className="font-bold text-xl md:text-3xl" style={{ backgroundColor: "skyblue",padding:"8px",borderRadius:"20px" }}>Select Your Image</p>
      </div>
      <div className="max-w-3xl mx-auto my-10">
        <div className="flex flex-col md:flex-row w-full gap-6">
          <input
            className="flex-1 py-2 outline-none rounded-md px-2 text-black text-xl"
            type="text"
            // placeholder="Location"
            // value={location}
            // onChange={(e) => setLocation(e.target.value)}
            value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Title"
          />
          <input
            className="flex-1 py-2 outline-none rounded-md px-2 text-black text-xl"
            type="text"
            placeholder="Description"
            // value={description}
            // onChange={(e) => setDescription(e.target.value)}
            value={description} onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div className="flex flex-col md:flex-row w-full gap-6 my-7">
          <button
            type="button"
            className="flex-1 py-2 outline-none rounded-md px-2 text-xl bg-[#48a3c6]"
            // onClick={upload}
            onClick={handleClick}
            
          >
            Upload Image
          </button>
          {/* <button
            type="button"
            onClick={() => {
              setImage(null);
              setLocation("");
              setDescription("");
            }}
            className="flex-1 py-2 outline-none rounded-md px-2 text-xl bg-[#48a3c6]"
          >
            Clear
          </button> */}
        </div>
      </div>
      <div>
        <h1 className="text-3xl text-center">Image Gallery</h1>
        <div className="inner p-8 flex flex-col gap-10">
          {data.map(value => (
            <div
              className="box flex flex-col md:flex-row gap-10"
              key={value.id}
            >
              <img
                // src={`/images/${record.imageName}`}
                src={value.imgUrl} alt={`Image - ${value.id}`}
                className="md:h-52 max-w-sm rounded-md"
                
              />
              <div className="flex flex-col">
                <p className="text-xl mb-4">Location : {value.title}</p>
                <p className="text-xl max-w-xl">
                  Description : {value.description}
                </p>
                <button
                  className="max-w-xs mt-10 py-2 outline-none rounded-md px-2 text-xl bg-[#48a3c6]"
                  onClick={() => handleDelete(value.id)}
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
