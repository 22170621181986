import React from "react";

const GoogleMap = () => {
  const mapSrc = `https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7814.458662168464!2d78.143102!3d11.678153!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3babf05af1d5f959%3A0xbb3b5c8cebe41986!2sAP%20Medical%20Center!5e0!3m2!1sen!2sin!4v1696771839288!5m2!1sen!2sin`;

  return (
    <iframe
      title="Google Map"
      src={mapSrc}
      width="100%"
      height="450"
      style={{ border: 0, marginLeft: "auto", marginRight: "auto" }}
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
  );
};

export default GoogleMap;